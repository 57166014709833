<template>
  <transition name="fade">
    <Loader v-if="!state.quote.id" key="loader" />

    <div v-else-if="print" key="print" class="flex flex-row bg-white" :style="cssVariables">
      <Printer />
    </div>

    <div v-else key="loaded" class="flex flex-row h-screen bg-gray-50" :style="cssVariables">
      <div class="w-1/4 sm:hidden h-full bg-white shadow-lg z-20 overflow-auto">
        <EditorSidebar v-if="state.editMode" />
        <Sidebar v-else />
      </div>

      <div class="relative w-3/4 sm:w-full h-full overflow-hidden">
        <Title v-if="state.currentPage && state.quote.settings.show_title" :title="state.currentPage.title" class="absolute top-8 left-10" />

        <transition :name="transitionName" mode="out-in">
          <Page v-if="state.currentPage" :page="state.currentPage" :key="state.currentPage.key" class="h-full overflow-y-scroll" />
        </transition>
      </div>

      <Pagination class="fixed bottom-0 z-10" />

      <button class="fixed top-3 right-6 sm:hidden" @click="printPages">
        <PrintIcon class="h-4 w-4 text-primary" />
      </button>
    </div>
  </transition>
</template>

<script>
import Sidebar from './layout/Sidebar.vue'
import EditorSidebar from './editor/Sidebar.vue'
import Title from './layout/Title.vue'
import Pagination from './layout/Pagination.vue'
import Loader from './layout/Loader.vue'
import PrintIcon from './assets/print.svg'
import Page from './layout/Page.vue'
import Printer from './layout/Printer.vue'
import state from './state'

export default {
  components: {
    Sidebar,
    EditorSidebar,
    Title,
    Pagination,
    Loader,
    Page,
    PrintIcon,
    Printer
  },
  props: {
    print: { type: Boolean, default: false }
  },
  data () {
    return {
      transitionName: 'slide-down',
      state
    }
  },
  computed: {
    cssVariables () {
      return {
        '--color-primary': state.quote.customer.theme.main_color,
        '--color-secondary': state.quote.customer.theme.accent_color
      }
    }
  },
  created () {
    state.load().then(() => {
      document.title = state.quote.title

      if (!state.editMode) {
        this.loadTracker()
      }
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (from.params.page > to.params.page) {
      this.transitionName = 'slide-down'
    } else {
      this.transitionName = 'slide-up'
    }

    next()
  },
  methods: {
    printPages () {
      this.$router.push({ params: { page: 'print' } })
    },
    loadTracker () {
      /* eslint-disable */
      (function (n, x, t, m, o, v, e) {
        n[o] = n[o] || function () { (n[o].q = n[o].q || []).push(arguments) }
        v = x.createElement(t), e = x.getElementsByTagName(t)[0]; v.async = 1; v.src = m; e.parentNode.insertBefore(v, e)
      })(window, document, 'script', 'https://nexxtmove-313510.web.app/nm.js', 'nm')

      window.nm('init', state.quote.customer.id)
      window.nm('autotrack')
      /* eslint-enable */
    }
  }
}
</script>
