<template>
  <img :src="content.image_url" class="w-full">
</template>

<script>
import templateMixin from './template'

export default {
  mixins: [templateMixin]
}
</script>
