<template>
  <div class="relative py-10 px-20 h-full">
    <img :src="state.quote.customer.logo.url" class="max-h-30">
    <Menu class="mt-24" />
  </div>
</template>

<script>
import Menu from './Menu.vue'
import state from '../state'

export default {
  components: { Menu },
  data () {
    return { state }
  }
}
</script>
