import Vue from 'vue'
import router from './router'
import number from './util/number'
import './index.css'

Vue.filter('number', number)

new Vue({
  router,
  render: h => h('router-view')
}).$mount('#app')
