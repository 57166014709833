<template>
  <div>
    <ImageSettings :row="row" />
    <hr class="my-5">
    <TextboxSettings :row="row" />
  </div>
</template>

<script>
import ImageSettings from './ImageSettings.vue'
import TextboxSettings from './TextboxSettings.vue'

export default {
  components: { ImageSettings, TextboxSettings },
  props: {
    row: Object
  }
}
</script>
