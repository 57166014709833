<template>
  <div>
    <p class="text-sm mb-2">Items</p>

    <DragContainer :items.sync="row.content.items" lock-axis="y" non-drag-area-selector=".list-item__text">
      <Draggable v-for="(item, i) in row.content.items" :key="i">
        <div class="mt-1 flex shadow-sm">
          <button v-if="row.template_settings.numeric" class="inline-flex items-center justify-center w-9 rounded-l-md border border-r-0 border-gray-300 bg-white text-xs text-gray-500 cursor-default">
            {{ i + 1 }}
          </button>
          <button v-else class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-white hover:bg-gray-50 text-xs text-gray-500 cursor-pointer" @click="openIconSelect(i)">
            <i class="fa fa-check"></i>
          </button>

          <input type="text" v-model="item.text" class="list-item__text flex-1 min-w-0 block w-full px-3 py-2 rounded-none border-gray-300 text-xs cursor-text">
          <button class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-white hover:bg-gray-50 text-xs text-gray-500 cursor-pointer" @click="removeItem(i)">
            <i class="fa fa-times"></i>
          </button>
        </div>

        <FontAwesomePicker v-if="iconSelectIndex === i" seachbox="Zoek icoon" @selectIcon="selectIcon(item, $event)" />
      </Draggable>
    </DragContainer>

    <Btn class="mt-2" @click="addItem"><i class="fa fa-plus"></i> Item toevoegen</Btn>

    <div class="mt-5">
      <label for="email" class="block text-xs text-gray-500">Icoonkleur</label>
      <div class="mt-1">
        <ColorPicker v-model="row.template_settings.icon_color" />
      </div>
    </div>

    <div class="flex items-center mt-3 mb-3">
      <input id="autoplay" v-model="row.template_settings.numeric" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
      <label for="autoplay" class="text-2xs ml-1">Genummerde lijst</label>
    </div>
  </div>
</template>

<script>
import state from '../../state'
import Btn from '../../editor/ui/Btn.vue'
import DragContainer from '../../editor/ui/DragContainer.vue'
import { Draggable } from 'vue-smooth-dnd'
import { fontAwesomePicker } from 'font-awesome-picker'
import ColorPicker from '../../editor/ui/ColorPicker.vue'

export default {
  components: { Btn, DragContainer, Draggable, FontAwesomePicker: fontAwesomePicker, ColorPicker },
  props: {
    row: Object
  },
  data () {
    return {
      state,
      iconSelectIndex: null
    }
  },
  methods: {
    addItem () {
      this.row.content.items.push({
        icon: 'check',
        text: 'Nieuw item'
      })
    },
    removeItem (i) {
      this.row.content.items.splice(i, 1)
    },
    openIconSelect (i) {
      if (this.iconSelectIndex === i) {
        this.iconSelectIndex = null
      } else {
        this.iconSelectIndex = i
      }
    },
    selectIcon (item, icon) {
      const name = icon.className.replace('fa-', '')
      item.icon = name
    }
  }
}
</script>

<style>
#iconPicker {
  @apply bg-gray-50 rounded-md mt-1 mb-3;
  max-width: unset;
}

.iconPicker__header {
  display: none;
}

.iconPicker__body {
  @apply border border-gray-300 rounded-md;
}

.iconPicker__icons .item {
  @apply bg-white hover:bg-gray-50;
  padding: 5px;
  cursor: pointer !important;
}

.iconPicker__icons .item i {
  @apply text-lg;
  cursor: pointer !important;
}
</style>
