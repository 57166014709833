<template>
  <div class="flex flex-col justify-between bg-white w-72 sm:w-full mx-5 mb-10 shadow-md hover:shadow-lg transition-shadow duration-200 p-5" :class="classes">
    <div class="mb-5">
      <h3 class="text-xl text-primary font-bold text-center mb-5" :style="size(plan.name_size)">{{ plan.name }}</h3>
      <p class="text-sm">{{ plan.description }}</p>

      <fieldset class="space-y-2 mt-3">
        <div v-for="(option, i) in plan.options" :key="i" class="flex">
          <div v-if="plan.options_selectable" class="flex items-center h-5">
            <input :id="`option_${i}`" v-model="option.selected" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
          </div>
          <div v-else>
            <i class="fa fa-check"></i>
          </div>
          <div class="ml-2 text-sm">
            <label :for="plan.options_selectable ? `option_${i}` : null" class="text-xs">
              {{ option.name }}

              <small v-if="plan.options_selectable" class="text-gray-500">
                <span v-if="option.price">á € {{ option.price | number }}</span>
                <span v-else>gratis</span>
              </small>
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <div>
      <div v-if="!plan.hide_cost">
        <hr class="mb-3">

        <div class="flex items-center justify-between">
          <div>
            <div class="font-bold" :style="size(plan.cost_title_size)">{{ plan.cost_title }}</div>
          </div>

          <div class="text-primary text-right">
            <div v-if="plan.price_type === 'percentage'" class="font-bold" :style="size(plan.price_size)">{{ totalPrice | number }}%</div>
            <div v-else class="font-bold" :style="size(plan.price_size)">€ {{ totalPrice | number }}</div>
          </div>
        </div>

        <div class="flex items-center justify-between mb-8">
          <div>
            <div v-if="plan.cost_desc" :style="size(plan.cost_desc_size)">{{ plan.cost_desc }}</div>
          </div>

          <div class="text-primary text-right">
            <div :style="size(plan.price_desc_size)">{{ plan.price_desc }}</div>
          </div>
        </div>
      </div>

      <div class="text-center" v-if="!plan.readonly">
        <Button class="mb-2" :style="buttonStyle" @click="confirm">{{ plan.button_text || `${plan.name} kiezen` }}</Button>
        <div class="text-gray-500" :style="size(plan.bottom_text_size)">{{ plan.bottom_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../layout/Button.vue'
import state from '../state'

export default {
  components: { Button },
  props: {
    plan: Object
  },
  computed: {
    totalPrice () {
      return calculateTotalPrice(this.plan)
    },
    classes () {
      const classes = []

      if (this.plan.featured) {
        classes.push('border-4 border-secondary rounded')
      }

      return classes
    },
    buttonStyle () {
      const style = {}

      if (this.plan.button_color) {
        style.backgroundColor = this.plan.button_color
      }

      style.fontSize = `${this.plan.button_text_size / 10}rem`

      return style
    }
  },
  created () {
    const defaults = {
      cost_title: 'Kosten',
      price_desc: 'Inclusief BTW',

      name_size: 12,
      price_size: 12,
      cost_title_size: 10,
      cost_desc_size: 5,
      price_desc_size: 5,
      button_text_size: 10,

      popup_title: 'Bevestigen',
      popup_text: `Wil je het geselecteerde pakket aanvragen? We zullen dan binnenkort contact met ${state.formal ? 'u' : 'je'} opnemen, pas daarna zal de aanvraag definitief worden.`,

      bottom_text: 'Dit aanbod is 30 dagen geldig.',
      bottom_text_size: 5,

      options_selectable: !this.plan.readonly
    }

    for (const [key, defaultValue] of Object.entries(defaults)) {
      if (this.plan[key] === undefined) {
        this.$set(this.plan, key, defaultValue)
      }
    }
  },
  methods: {
    confirm () {
      return state.accept(this.plan)
    },
    size (size) {
      return {
        fontSize: `${size / 10}rem`
      }
    }
  }
}

export function calculateTotalPrice (plan) {
  if (plan.price_type === 'percentage') {
    return plan.price
  }

  if (!plan.options_selectable) {
    return plan.price
  }

  const options = plan.options || []
  const optionsPrice = options.reduce((result, option) => {
    if (option.selected && option.price) {
      result += option.price
    }

    return result
  }, 0)

  return plan.price + optionsPrice
}
</script>
