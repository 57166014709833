import api from './util/api'
import { nanoid } from 'nanoid'
import router from './router'
import swal from 'sweetalert'

const state = {
  // State
  quote: {
    id: null,
    pages: [],
    background_image_url: null,
    customer: {
      logo: {},
      theme: {}
    },
    settings: {
      show_title: true
    }
  },

  customerSlug: null,
  quoteSlug: null,

  editToken: null,

  selectedTab: 'pages',
  selectedPage: null,
  selectedRow: null,

  saved: false,
  saving: false,

  // Getters
  get formal () {
    return state.quote.customer.communication_style === 'formal'
  },
  get editMode () {
    return state.editToken !== null
  },
  get currentPage () {
    return state.quote.pages[state.selectedPage - 1]
  },

  // Methods
  load () {
    const params = {
      include: 'customer.logo,customer.theme'
    }

    const promises = [api.get(`/quotes/${state.customerSlug}/${state.quoteSlug}`, { params })]

    if (!state.editMode) {
      promises.push(delay(1900)) // Wait at least 1.9s (for loading animation)
    }

    return Promise.all(promises)
      .then(([response]) => {
        Object.assign(state.quote, response.data.data)

        if (!state.quote.settings) {
          state.quote.settings = {
            show_title: true
          }
        }

        state.saved = true
      })
  },

  save () {
    state.saving = true
    return api.patch(`/quotes/${state.quote.id}`, state.quote)
      .then((result) => {
        state.quoteSlug = state.quote.slug = result.data.data.slug

        state.sendEvent('saved')
      })
      .finally(() => {
        state.saving = false
      })
  },

  accept (plan) {
    const confirm = swal({
      title: plan.popup_title,
      text: plan.popup_text,
      icon: 'info',
      buttons: {
        confirm: { text: 'Ja', value: true, visible: true },
        cancel: { text: 'Nee', value: false, visible: true }
      }
    })

    confirm.then((yes) => {
      if (!yes) {
        return
      }

      return api.post(`/quotes/${state.quote.id}/accept`, plan)
        .then(() => swal({
          title: 'Bedankt!',
          text: `${state.formal ? 'U heeft' : 'Je hebt'} het geselecteerde pakket succesvol aangevraagd. We zullen binnenkort contact met ${state.formal ? 'u' : 'je'} opnemen, pas daarna zal de aanvraag definitief worden.`,
          icon: 'success',
          buttons: false
        }))
    })
  },

  notify (text) {
    return api.post(`/quotes/${state.quote.id}/notify`, { text })
  },

  addPage () {
    const next = state.quote.pages.length + 1
    const title = `Pagina ${next}`

    state.quote.pages.push({
      key: nanoid(),
      title,
      rows: []
    })

    state.selectedRow = null

    router.push({ params: { page: next } })
  },

  goBack () {
    if (state.selectedRow) {
      state.selectedRow = null
      return
    }

    if (state.selectedTab !== 'pages') {
      state.selectedTab = 'pages'
      return
    }

    state.confirmLeave()
      .then((willExit) => {
        if (!willExit) {
          return
        }

        state.sendEvent('exited')
      })
  },

  confirmLeave () {
    if (state.saved) {
      return Promise.resolve(true)
    }

    return swal({
      title: 'Wil je weggaan?',
      text: 'Je hebt onopgeslagen wijzigingen, weet je zeker dat je deze wil negeren?',
      icon: 'warning',
      buttons: ['Annuleren', 'Wijzigingen negeren'],
      dangerMode: true
    })
  },

  sendEvent (e) {
    window.parent.postMessage(e, '*')
  }
}

export default state

function delay (ms) {
  return new Promise(function (resolve) {
    setTimeout(() => resolve(), ms)
  })
}
