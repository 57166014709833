<template>
  <div class="flex sm:flex-col justify-center items-center">
    <img :src="content.image_url" class="w-80 sm:w-full">
    <div class="textbox ml-20 sm:ml-0 sm:p-10" v-html="content.text"></div>
  </div>
</template>

<script>
import templateMixin from './template'

export default {
  mixins: [templateMixin]
}
</script>

<style>
.textbox h1 {
  @apply text-3xl;
}
.textbox h2 {
  @apply text-2xl;
}
</style>
