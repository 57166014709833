import axios from 'axios'
import state from '../state'

const http = axios.create({
  baseURL: 'https://api.nexxtmove.nl'
})

http.interceptors.request.use(
  (request) => {
    // Add auth token to each request
    if (state.editToken) {
      request.headers.Authorization = `Bearer ${state.editToken}`
    }

    return request
  },
  (error) => Promise.reject(error)
)

export default http
