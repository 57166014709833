<template>
  <div class="bg-white border-2 border-gray-100 py-3 flex flex-col justify-center items-center text-center">
    <component :is="require(`../../assets/metronic/${definition.icon}.svg?inline`)" class="h-10 mb-2" :class="iconClass" />

    <div class="text-xs text-gray-500">
      {{ definition.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    definition: Object,
    iconClass: String
  }
}
</script>
