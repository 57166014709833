<template>
  <button class="menu-item relative w-full flex justify-between items-center mb-5 text-gray-300 text-xl font-medium" :class="{ 'text-primary': isActive }" v-on="$listeners">
    <div class="flex justify-start items-center">
      <span class="mr-2 border-2 rounded-full h-10 w-10 inline-flex items-center justify-center" :class="{ 'border-secondary': isActive }">{{ number }}</span>

      <input v-if="editable" type="text" :value="label" class="label-edit text-xl font-medium" style="width: 80%" @input="rename">
      <span v-else>{{ label }}</span>
    </div>

    <div v-if="editable" class="menu-item__actions hidden cursor-pointer">
      <div class="w-11">
        <button @click="edit"><i class="fa fa-pen"></i></button>
        <button @click="remove"><i class="fa fa-times"></i></button>
      </div>
    </div>
  </button>
</template>

<script>
import state from '../state'

export default {
  props: {
    label: String,
    number: [Number, String],
    editable: { type: Boolean, default: false }
  },
  computed: {
    isActive () {
      return state.selectedPage === this.number
    }
  },
  methods: {
    rename (e) {
      this.$emit('rename', e.target.value)
    },
    remove () {
      this.$emit('remove')
    },
    edit () {
      this.$router.push({ params: { page: this.number } })
      state.selectedTab = 'content'
    }
  }
}
</script>

<style scoped>
.menu-item:hover .menu-item__actions {
  display: inline-block;
}

.label-edit {
  padding: 0;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
