<template>
  <div>
    <Btn @click="browse" :disabled="loading">
      <i v-if="loading" class="fa fa-spinner fa-spin"></i>
      <i v-else class="fa fa-upload"></i>
      <span class="ml-0.5">Wijzig afbeelding</span>
    </Btn>
    <input ref="fileInput" type="file" accept="image/*" class="hidden" @change="upload">
  </div>
</template>

<script>
import state from '../../state'
import Btn from '../../editor/ui/Btn.vue'
import api from '../../util/api'

export default {
  components: { Btn },
  props: {
    row: Object
  },
  data () {
    return {
      state,
      loading: false
    }
  },
  methods: {
    browse () {
      this.$refs.fileInput.click()
    },
    upload (e) {
      this.loading = true

      const formData = new FormData()

      const file = e.target.files[0]
      formData.append('file', file)

      return api.post('/media', formData)
        .then((result) => {
          if (this.row) {
            this.row.content.image_url = result.data.data.url
          }

          this.$emit('change', result.data.data.url)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
