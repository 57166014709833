<template>
  <div class="w-3/4 mx-auto">
    <Page v-for="page in state.quote.pages" :page="page" :key="page.key" class="print-page min-h-screen overflow-hidden" />
  </div>
</template>

<script>
import Page from './Page.vue'
import state from '../state'

export default {
  components: { Page },
  data () {
    return { state }
  },
  mounted () {
    setTimeout(() => {
      window.print()
      setTimeout(() => {
        this.$router.go(-1)
      }, 100)
    }, 1000)
  }
}
</script>

<style>
.print-page {
  page-break-after: always;
  padding: 0 !important;
}

.print-page .spacer {
  display: none;
}

/* Remove space below last row */
.print-page > .page-rows > div:nth-last-of-type(2) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
</style>
