import Vue from 'vue'
import VueRouter from 'vue-router'
import state from './state'

import App from './App.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:customer/:slug/:page?',
    component: App,
    props ({ params, query }) {
      // Set state
      state.customerSlug = params.customer
      state.quoteSlug = params.slug
      state.selectedPage = Number(params.page) || 1

      if (query.edit) {
        state.editToken = query.edit
      }

      const props = {}

      if (params.page === 'print') {
        props.print = true
      }

      return props
    }
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
