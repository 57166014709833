export default {
  props: {
    content: Object,
    template_settings: [Object]
  },
  computed: {
    settings () {
      return this.template_settings
    }
  }
}
