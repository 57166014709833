<template>
  <div class="color-picker">
    <div class="inline-flex">
      <div class="inline-flex shadow-sm rounded-md" @click="toggle">
        <button :style="previewStyle" class="w-7 rounded-l-md border border-gray-300"></button>
        <button class="-ml-px items-center px-2 h-6 rounded-r-md border border-gray-300 bg-white text-2xs text-gray-700 hover:bg-gray-50">
          Kleur selecteren
        </button>
      </div>

      <button v-if="value" title="Beginwaarde" class="inline-flex items-center px-2 h-6 text-2xs text-gray-400 hover:bg-gray-50" @click="reset">
        <i class="fa fa-reply"></i>
      </button>
    </div>

    <picker v-if="show" :value="color" v-on-clickaway="close" @input="update" />
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  components: { picker: Chrome },
  mixins: [clickaway],
  props: {
    value: String
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    color () {
      return this.value || '#ffffff'
    },
    previewStyle () {
      return {
        backgroundColor: this.color
      }
    }
  },
  methods: {
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
    toggle () {
      this.show = !this.show
    },
    update ({ hex }) {
      this.$emit('input', hex)
    },
    reset () {
      this.$emit('input', null)
    }
  }
}
</script>

<style>
.color-picker {
  position: relative;
}

.vc-chrome {
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  z-index: 100;
  font-family: inherit !important;
}

.vc-chrome-toggle-btn,
.vc-chrome-color-wrap,
.vc-chrome-alpha-wrap {
  display: none;
}

.vc-chrome-fields-wrap {
  padding-top: 0 !important;
}
</style>
