<template>
  <div class="page-row" :style="style" v-on="$listeners">
    <component :is="template" v-bind="row" />
  </div>
</template>

<script>
import definitions from '../editor/definitions/row-definitions'
import state from '../state'

export default {
  props: {
    row: Object
  },
  computed: {
    isSelected () {
      return state.selectedRow?.key === this.row.key
    },
    template () {
      const definition = definitions.find(d => d.type === this.row.content_type)
      return definition.template
    },
    style () {
      const settings = this.row.template_settings

      const style = {
        paddingTop: `${settings.padding_top}rem`,
        paddingBottom: `${settings.padding_bottom}rem`,
        paddingLeft: `${settings.padding_left}rem`,
        paddingRight: `${settings.padding_right}rem`,

        marginTop: `${settings.margin_top}rem`,
        marginBottom: `${settings.margin_bottom}rem`,
        marginLeft: `${settings.margin_left}rem`,
        marginRight: `${settings.margin_right}rem`,

        backgroundColor: settings.bg_color,
        color: settings.text_color
      }

      if (settings.border_color) {
        style.border = `0.25rem solid ${settings.border_color}`
        style.borderRadius = '0.25rem'
      }

      return style
    }
  }
}
</script>
