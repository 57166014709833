<template>
  <div>
    <DragContainer :items.sync="row.content.buttons" lock-axis="y" drag-handle-selector=".button__header">
      <Draggable v-for="(button, i) in row.content.buttons" :key="i">
        <div class="border rounded-md mb-3 overflow-hidden bg-white">
          <div class="button__header flex justify-between items-center bg-gray-200 p-2 px-4">
            <h3 class="text-xs">Knop #{{ i + 1 }}</h3>

            <div>
              <Btn @click="duplicate(button)" class="mr-1 h-7"><i class="fa fa-copy"></i></Btn>
              <Btn @click="remove(i)" class="h-7"><i class="fa fa-times"></i></Btn>
            </div>
          </div>

          <div class="p-4 cursor-default">
            <label class="block text-2xs">Tekst</label>
            <div class="mt-1 mb-3 flex rounded-md shadow-sm">
              <input type="text" v-model="button.text" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              <input type="number" v-model.number="button.text_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
            </div>

            <label class="block text-2xs">Kleur</label>
            <div class="mt-1 mb-3">
              <ColorPicker v-model="button.color" />
            </div>

            <div>
              <label class="block text-2xs">Actie</label>
              <select v-model="button.action" class="mt-1 mb-3 block w-full pl-3 pr-10 py-1 text-2xs border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="link">Open link</option>
                <option value="email">Notificeer medewerker</option>
                <option v-if="availablePlans.length" value="plan">Kies pakket</option>
              </select>

              <div v-if="button.action === 'link'">
                <label class="block text-2xs">Link</label>
                <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                  <input type="text" :placeholder="state.quote.customer.website" v-model="button.link" class="flex-1 rounded-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                </div>
              </div>

              <div v-else-if="button.action === 'email'">
                <div class="rounded-md bg-blue-50 p-4 mb-3">
                  <p class="text-2xs text-blue-700">
                    Stuur een notificatiemail naar de gekoppelde medewerker (bij "Instellingen").<br>
                    Indien er geen medewerker is gekoppeld, wordt de notificatie verstuurd naar {{state.quote.customer.email_address}}
                  </p>
                </div>

                <label class="block text-2xs">Inhoud notificatie</label>
                <div class="mt-1 mb-3">
                  <textarea type="text" v-model="button.mail_text" class="shadow-sm block w-full py-1 text-2xs border-gray-300 rounded-md cursor-text"></textarea>
                </div>

                <label class="block text-2xs">Bedanktekst na klikken</label>
                <div class="mt-1 mb-3">
                  <textarea type="text" v-model="button.popup_text" class="shadow-sm block w-full py-1 text-2xs border-gray-300 rounded-md cursor-text"></textarea>
                </div>
              </div>

              <div v-else-if="button.action === 'plan'">
                <label class="block text-2xs">Pakket</label>
                <select v-model="button.plan" class="mt-1 mb-3 block w-full pl-3 pr-10 py-1 text-2xs border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                  <option v-for="(plan, i) in availablePlans" :key="i" :value="i">{{ plan.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </DragContainer>

    <Btn class="mt-2" @click="add"><i class="fa fa-plus"></i> Knop toevoegen</Btn>
  </div>
</template>

<script>
import DragContainer from '../ui/DragContainer.vue'
import { Draggable } from 'vue-smooth-dnd'
import Btn from '../ui/Btn.vue'
import ColorPicker from '../ui/ColorPicker.vue'
import state from '../../state'
import { getPlans } from '../../templates/Plans.vue'

export default {
  components: { Btn, DragContainer, Draggable, ColorPicker },
  props: {
    row: Object
  },
  data () {
    return { state }
  },
  computed: {
    availablePlans () {
      return getPlans(state.currentPage)
    }
  },
  methods: {
    add () {
      this.row.content.buttons.push({
        text: 'Nieuwe knop',
        action: 'link'
      })
    },
    remove (i) {
      this.row.content.buttons.splice(i, 1)
    },
    duplicate (button) {
      this.row.content.buttons.push({ ...button })
    }
  }
}
</script>
