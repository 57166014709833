<template>
  <button :class="classes" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    light: { type: Boolean, default: false }
  },
  computed: {
    classes () {
      const result = ['py-3', 'px-8', 'rounded-full', 'focus:outline-none', 'inline-flex', 'justify-center', 'items-center']

      // Hover
      result.push('hover:shadow-lg', 'transition-shadow', 'duration-200')

      if (this.light) {
        result.push('bg-gray-50', 'text-gray-400', 'border', 'border-gray-400')
      } else {
        result.push('bg-primary', 'text-white')
      }

      return result
    }
  }
}
</script>
