<template>
  <div class="w-full py-5 px-10 flex justify-end items-center bg-gradient-to-t from-gray-100">
    <Button v-if="previousPage" prev light class="mr-5" @click="goPrevious">
      <Arrow class="mr-8 inline-block h-4 transform rotate-180" />
      Vorige
    </Button>

    <Button v-if="nextPage" next @click="goNext">
      Volgende
      <Arrow class="ml-8 inline-block h-4" />
    </Button>
  </div>
</template>

<script>
import Button from './Button.vue'
import Arrow from '../assets/arrow.svg'
import state from '../state'

export default {
  components: { Button, Arrow },
  computed: {
    currentPage () {
      return state.selectedPage
    },
    currentPageIndex () {
      return this.currentPage - 1
    },
    previousPage () {
      return state.quote.pages[this.currentPageIndex - 1]
    },
    nextPage () {
      return state.quote.pages[this.currentPageIndex + 1]
    }
  },
  methods: {
    goPrevious () {
      if (!this.previousPage) return

      this.$router.push({
        params: {
          page: this.currentPage - 1
        }
      })
    },
    goNext () {
      if (!this.nextPage) return

      this.$router.push({
        params: {
          page: this.currentPage + 1
        }
      })
    }
  }
}
</script>
