<template>
  <Button class="mr-3 mb-3" :style="buttonStyle" @click="handle">{{ button.text }}</Button>
</template>

<script>
import Button from '../layout/Button.vue'
import state from '../state'
import swal from 'sweetalert'
import { getPlans } from './Plans.vue'

export default {
  components: { Button },
  props: {
    button: Object
  },
  computed: {
    buttonStyle () {
      const style = {}

      if (this.button.color) {
        style.backgroundColor = this.button.color
      }

      if (this.button.text_size) {
        style.fontSize = `${this.button.text_size / 10}rem`
      }

      return style
    },
    availablePlans () {
      return getPlans(state.currentPage)
    }
  },
  created () {
    const defaults = {
      text_size: 10
    }

    for (const [key, defaultValue] of Object.entries(defaults)) {
      if (this.button[key] === undefined) {
        this.$set(this.button, key, defaultValue)
      }
    }
  },
  methods: {
    handle () {
      this[`${this.button.action}Action`]()
    },
    linkAction () {
      window.open(this.button.link, '_blank').focus()
    },
    emailAction () {
      state.notify(this.button.mail_text)
        .then(() => swal({
          title: 'Bedankt!',
          text: this.button.popup_text,
          icon: 'success',
          buttons: false
        }))
    },
    planAction () {
      const plan = this.availablePlans[this.button.plan]
      state.accept(plan)
    }
  }
}
</script>
