<template>
  <div class="flex flex-col items-center py-20 bg-cover bg-local" :style="pageStyle" @click="select(null)">
    <DragContainer v-if="state.editMode" :items.sync="page.rows" :drop-placeholder="dropPlaceholder" group-name="content" lock-axis="y" class="w-3/4 sm:w-4/5 min-h-full" @drop="select">
      <Draggable v-for="row in page.rows" :key="row.key" class="draggable-row relative hover:ring-4 ring-nexxtmove" :class="{ 'draggable-row--selected ring-4 ring-nexxtmove': state.selectedRow === row }">
        <Row :row="row" @click.stop="select(row)" />

        <div class="draggable-row__actions hidden justify-between absolute bg-nexxtmove text-sm text-white transform -translate-y-full">
          <div class="p-1">{{ definitions[row.content_type].title }}</div>

          <div>
            <button class="px-2 h-full bg-black bg-opacity-10 hover:bg-opacity-20">
              <i class="fa fa-arrows-alt-v"></i>
            </button>
            <button class="px-2 h-full bg-black bg-opacity-10 hover:bg-opacity-20 cursor-pointer" @click.stop="duplicate(row)">
              <i class="fa fa-copy"></i>
            </button>
            <button class="px-2 h-full bg-black bg-opacity-10 hover:bg-opacity-20 cursor-pointer" @click="remove(row)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </Draggable>

      <div class="spacer h-20"></div>
    </DragContainer>

    <div v-else class="page-rows w-3/4 sm:w-4/5 min-h-full">
      <Row v-for="row in page.rows" :key="row.key" :row="row" />
      <div class="spacer h-20"></div>
    </div>
  </div>
</template>

<script>
import DragContainer from '../editor/ui/DragContainer.vue'
import Row from '../templates/Row.vue'
import { Draggable } from 'vue-smooth-dnd'
import state from '../state'
import { keyed as definitions } from '../editor/definitions/row-definitions'
import { nanoid } from 'nanoid'

export default {
  components: { DragContainer, Draggable, Row },
  props: {
    page: Object
  },
  data () {
    return {
      state
    }
  },
  computed: {
    definitions () {
      return definitions
    },
    dropPlaceholder () {
      return {
        className: 'bg-white border-2 border-dashed border-secondary',
        animationDuration: '300'
      }
    },
    pageStyle () {
      const style = {}

      if (state.quote.background_image_url) {
        style.backgroundImage = `url(${state.quote.background_image_url})`
      }

      return style
    }
  },
  methods: {
    remove (row) {
      this.page.rows = this.page.rows.filter(r => r.key !== row.key)
    },
    duplicate (row) {
      const index = this.page.rows.findIndex(r => r.key === row.key)

      const newRow = JSON.parse(JSON.stringify(row))
      newRow.key = nanoid()

      this.page.rows.splice(index + 1, 0, newRow)
      this.select(newRow)
    },
    select (row) {
      state.selectedRow = row
      state.selectedTab = 'content'
    }
  }
}
</script>

<style scoped>
.draggable-row:hover,
.draggable-row--selected {
  z-index: 999;
}

.draggable-row:hover .draggable-row__actions,
.draggable-row--selected .draggable-row__actions {
  display: flex;
}

.draggable-row__actions {
  top: 0;
  left: -4px;
  right: -4px;
}

.smooth-dnd-container:empty::before {
  @apply absolute w-full h-20 flex justify-center items-center text-gray-300 bg-white border-2 border-dashed;
  content: "Sleep blokjes hierheen om toe te voegen aan deze pagina";
}
</style>
