<template>
  <div class="flex flex-wrap">
    <div v-for="(feature, i) in content.items" :key="i" class="w-1/2 sm:w-full mb-10 flex items-center" :class="{ 'pr-10': i % 2 === 0 }">
      <div class="mr-5 flex flex-col justify-center items-center">
        <div v-if="settings.numeric" class="text-2xl w-8 text-center" :style="colorStyle">{{ i + 1 }}</div>
        <component v-else-if="icons[feature.icon]" :is="icons[feature.icon]" class="inline-block h-10 text-primary" :style="colorStyle" />
        <i v-else :class="`fa fa-${feature.icon} text-5xl`" :style="colorStyle"></i>
        <div class="h-0.5 w-full bg-secondary mt-2"></div>
      </div>
      <p class="text-lg">{{ feature.text }}</p>
    </div>
  </div>
</template>

<script>
import templateMixin from './template'
import state from '../state'

import check from '../assets/check.svg'
import exposure from '../assets/exposure.svg'
import brochure from '../assets/brochure.svg'
import camera from '../assets/camera.svg'
import choices from '../assets/choices.svg'
import marker from '../assets/marker.svg'
import sold from '../assets/sold.svg'
import vip from '../assets/vip.svg'
import window from '../assets/window.svg'

export default {
  mixins: [templateMixin],
  data () {
    return { state }
  },
  computed: {
    icons () {
      return { check, exposure, brochure, camera, choices, marker, sold, vip, window }
    },
    colorStyle () {
      return { color: this.settings.icon_color }
    }
  }
}
</script>
