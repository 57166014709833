<template>
  <div class="relative h-full">
    <div class="w-full h-12 flex items-center justify-between bg-nexxtmove-secondary text-white text-sm">
      <button class="px-5 h-full" @click="state.goBack">
        <Arrow class="mr-2 inline-block h-3 transform rotate-180" />
        Terug
      </button>

      <button class="bg-black px-5 bg-opacity-20 hover:bg-opacity-30 h-full" @click="state.save">
        <i v-if="state.saving" class="fa fa-spinner fa-spin text-base mr-1"></i>
        <Floppy v-else class="mr-2 inline-block h-3" />
        Opslaan
      </button>
    </div>

    <div class="py-5 px-10">
      <Tabs :tabs="tabs" @select="selectTab" />

      <transition name="slide-left" mode="out-in">
        <div v-if="state.selectedTab === 'pages'" key="pages" class="pt-5">
          <p class="text-sm">Selecteer welke pagina's je wil tonen op de offerte:</p>

          <Menu class="mt-10" />
        </div>

        <div v-else-if="state.selectedTab === 'content'" key="content" class="pt-5">
          <transition name="slide-left" mode="out-in">
            <RowSettings v-if="state.selectedRow" :row="state.selectedRow" :key="state.selectedRow.key" />

            <div v-else>
              <p class="text-sm">Sleep blokjes naar je pagina:</p>
              <RowDefinitions />
            </div>
          </transition>
        </div>

        <div v-if="state.selectedTab === 'settings'" key="settings" class="pt-5">
          <QuoteSettings />
        </div>

        <div v-else-if="state.selectedTab === 'send'" key="send" class="pt-5">
          <p class="text-sm">Om de offerte te versturen kun je onderstaande link naar de klant mailen:</p>
          <br>
          <a :href="previewUrl" class="text-sm text-nexxtmove" target="_blank"><i class="fa fa-external-link-alt"></i> {{ previewUrl }}</a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Tabs from '../editor/ui/Tabs.vue'
import Menu from '../layout/Menu.vue'
import Arrow from '../assets/arrow.svg'
import Floppy from '../assets/floppy.svg'
import RowSettings from './settings/RowSettings.vue'
import QuoteSettings from './settings/QuoteSettings.vue'
import state from '../state'
import RowDefinitions from './definitions/RowDefinitions'

export default {
  components: { Tabs, Menu, Arrow, Floppy, RowSettings, RowDefinitions, QuoteSettings },
  data () {
    return { state }
  },
  computed: {
    tabs () {
      return [
        { id: 'pages', name: 'Menu', current: state.selectedTab === 'pages' },
        { id: 'content', name: 'Inhoud', current: state.selectedTab === 'content' },
        { id: 'settings', name: 'Instellingen', current: state.selectedTab === 'settings' },
        { id: 'send', name: 'Verstuur', current: state.selectedTab === 'send' }
      ]
    },
    previewUrl () {
      return `https://offerte.nexxtmove.nl/${state.customerSlug}/${state.quoteSlug}`
    }
  },
  methods: {
    selectTab (tab) {
      state.selectedTab = tab.id
    }
  }
}
</script>
