<template>
  <h1 class="text-primary font-medium text-lg" :title="state.editMode ? 'Je kunt de titel aanpassen bij de menu-instellingen in het linkerscherm' : ''">
    <span class="border-b-2 border-secondary">{{ title }}</span>
  </h1>
</template>

<script>
import state from '../state'

export default {
  props: {
    title: String
  },
  data () {
    return { state }
  }
}
</script>
