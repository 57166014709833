<template>
  <div>
    <p class="text-sm mb-2">Tekst</p>
    <Editor v-model="row.content.text" :options="editorOptions" />

    <transition name="slide-down">
      <div v-if="row.content.text.length > 700" class="rounded-b-md bg-blue-50 p-4">
        <p class="text-xs text-blue-700">
          <strong>Tip:</strong> gebruik niet te veel tekst op een pagina.
          <a href="#" class="underline" @click.prevent="state.addPage">Maak in plaats daarvan een nieuwe pagina.</a>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import state from '../../state'
import { quillEditor as Editor } from 'vue-quill-editor'
import Quill from 'quill'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

Quill.register(Quill.import('attributors/style/align'), true)

export default {
  components: { Editor },
  props: {
    row: Object
  },
  data () {
    const theme = state.quote.customer.theme
    const additionalColors = theme.additional_colors || []

    return {
      state,
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ header: [6, 5, 4, false, 3, 2, 1] }],
            [{ align: [] }],
            [{ color: [theme.main_color, theme.accent_color, ...additionalColors, ''] }],
            ['link']
          ]
        },
        placeholder: 'Vertel je verhaal...'
      }
    }
  }
}
</script>

<style>
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Typ URL:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Opslaan";
}

.ql-snow .ql-tooltip::before {
  content: "Bezoek URL:";
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: "Bewerken";
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Verwijderen";
}

/* Header sizes */
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before {
  content: "Kleinst";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
  content: "Kleiner";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before {
  content: "Klein";
}

.ql-snow .ql-picker.ql-header .ql-picker-item:before,
.ql-snow .ql-picker.ql-header .ql-picker-label:before {
  content: "Normaal";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
  content: "Groot";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
  content: "Groter";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
  content: "Grootst";
}
</style>
