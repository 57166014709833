<template>
  <div class="flex justify-center flex-wrap">
    <ActionButton v-for="(button, i) in content.buttons" :key="i" :button="button" />
  </div>
</template>

<script>
import templateMixin from './template'
import state from '../state'
import ActionButton from './ActionButton.vue'

export default {
  mixins: [templateMixin],
  components: { ActionButton },
  data () {
    return { state }
  }
}
</script>
