<template>
  <div class="flex justify-center flex-wrap">
    <Plan v-for="(plan, i) in content.plans" :key="i" :plan="plan" />
  </div>
</template>

<script>
import templateMixin from './template'
import Plan from './Plan.vue'
import state from '../state'

export default {
  mixins: [templateMixin],
  components: { Plan },
  data () {
    return { state }
  }
}

export function getPlans (page) {
  return page.rows
    .filter(r => r.content_type === 'price_boxes')
    .reduce((result, row) => {
      result.push(...row.content.plans)
      return result
    }, [])
}
</script>
