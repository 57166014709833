<template>
  <div>
    <DragContainer :items.sync="row.content.plans" lock-axis="y" drag-handle-selector=".plan__header">
      <Draggable v-for="(plan, i) in row.content.plans" :key="i">
        <div class="border rounded-md mb-3 overflow-hidden bg-white">
          <div class="plan__header flex justify-between items-center bg-gray-200 p-2 px-4">
            <h3 class="text-xs">Pakket #{{ i + 1 }}</h3>

            <div>
              <Btn @click="duplicate(plan)" class="mr-1 h-7"><i class="fa fa-copy"></i></Btn>
              <Btn @click="remove(i)" class="h-7"><i class="fa fa-times"></i></Btn>
            </div>
          </div>

          <div class="p-4 cursor-default">
            <label class="block text-2xs">Titel</label>
            <div class="mt-1 mb-3 flex rounded-md shadow-sm">
              <input type="text" v-model="plan.name" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              <input type="number" v-model.number="plan.name_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
            </div>

            <label class="block text-2xs">Beschrijving</label>
            <div class="mt-1 mb-3">
              <textarea type="text" v-model="plan.description" class="shadow-sm block w-full py-1 text-2xs border-gray-300 rounded-md cursor-text"></textarea>
            </div>

            <div class="flex items-center mb-3">
              <input :id="`featured-${i}`" v-model="plan.featured" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
              <label :for="`featured-${i}`" class="text-2xs ml-1">Pakket uitlichten</label>
            </div>

            <hr class="mb-3">

            <label class="block text-xs mb-2">Kosten</label>

            <div class="flex items-center mb-3">
              <input :id="`hide-cost-${i}`" v-model="plan.hide_cost" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
              <label :for="`hide-cost-${i}`" class="text-2xs ml-1">Kosten verbergen</label>
            </div>

            <div v-if="!plan.hide_cost">
              <label class="block text-2xs">Titel</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" v-model="plan.cost_title" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <input type="number" v-model.number="plan.cost_title_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <label class="block text-2xs">Prijs</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="number" v-model.number="plan.price" step="0.01" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <button class="inline-flex items-center px-3 border border-l-0 border-gray-300 bg-white hover:bg-gray-50 text-2xs text-gray-500 cursor-pointer" @click="togglePriceType(plan)">
                  <i v-if="plan.price_type === 'percentage'" class="fa fa-percent"></i>
                  <i v-else class="fa fa-euro-sign"></i>
                </button>
                <input type="number" v-model.number="plan.price_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <label class="block text-2xs">Beschrijving links</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" v-model="plan.cost_desc" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <input type="number" v-model.number="plan.cost_desc_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <label class="block text-2xs">Beschrijving rechts</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" v-model="plan.price_desc" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <input type="number" v-model.number="plan.price_desc_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>
            </div>

            <hr class="mb-3">

            <label class="block text-xs mb-2">Knop</label>

            <div class="flex items-center mb-3">
              <input :id="`readonly-${i}`" v-model="plan.readonly" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
              <label :for="`readonly-${i}`" class="text-2xs ml-1">Knop verbergen</label>
            </div>

            <div v-if="!plan.readonly">
              <label class="block text-2xs">Knoptekst</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" :placeholder="`${plan.name} kiezen`" v-model="plan.button_text" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <input type="number" v-model.number="plan.button_text_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <label class="block text-2xs">Knopkleur</label>
              <div class="mt-1 mb-3">
                <ColorPicker v-model="plan.button_color" />
              </div>

              <label class="block text-2xs">Tekst onder knop</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" v-model="plan.bottom_text" class="flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                <input type="number" v-model.number="plan.bottom_text_size" title="Tekstgrootte" class="border-l-0 rounded-r-md block w-10 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <hr class="mb-3">

              <label class="block text-xs mb-2">Popup</label>

              <label class="block text-2xs">Titel</label>
              <div class="mt-1 mb-3 flex rounded-md shadow-sm">
                <input type="text" v-model="plan.popup_title" class="flex-1 rounded-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
              </div>

              <label class="block text-2xs">Tekst</label>
              <div class="mt-1 mb-3">
                <textarea type="text" v-model="plan.popup_text" class="shadow-sm block w-full py-1 text-2xs border-gray-300 rounded-md cursor-text"></textarea>
              </div>
            </div>

            <hr class="mb-3">

            <label class="block text-xs mb-2">Opties</label>

            <DragContainer :items.sync="plan.options" lock-axis="y" non-drag-area-selector=".option__input">
              <Draggable v-for="(option, i) in plan.options" :key="i">
                <div class="mb-1 flex rounded-md shadow-sm">
                  <input type="text" v-model="option.name" placeholder="Naam" class="option__input flex-1 rounded-l-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                  <input type="number" v-model.number="option.price" placeholder="Prijs" class="option__input border-l-0 block w-16 px-3 pr-0 py-1 rounded-none border-gray-300 text-2xs cursor-text">
                  <button class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-white hover:bg-gray-50 text-2xs text-gray-500 cursor-pointer" @click="removeOption(plan, i)">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </Draggable>
            </DragContainer>

            <Btn @click="addOption(plan)" class="mb-3"><i class="fa fa-plus"></i> Optie toevoegen</Btn>

            <div class="flex items-center mb-3">
              <input :id="`options-selectable-${i}`" v-model="plan.options_selectable" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
              <label :for="`options-selectable-${i}`" class="text-2xs ml-1">Opties selecteerbaar</label>
            </div>
          </div>
        </div>
      </Draggable>
    </DragContainer>

    <Btn class="mt-2" @click="add"><i class="fa fa-plus"></i> Pakket toevoegen</Btn>
  </div>
</template>

<script>
import DragContainer from '../ui/DragContainer.vue'
import { Draggable } from 'vue-smooth-dnd'
import Btn from '../ui/Btn.vue'
import ColorPicker from '../ui/ColorPicker.vue'

export default {
  components: { Btn, DragContainer, Draggable, ColorPicker },
  props: {
    row: Object
  },
  methods: {
    add () {
      this.row.content.plans.push({
        name: 'Nieuw pakket',
        description: '',
        price: 299,
        price_type: 'euro',
        options: [],
        readonly: false
      })
    },
    remove (i) {
      this.row.content.plans.splice(i, 1)
    },
    duplicate (plan) {
      this.row.content.plans.push({ ...plan })
    },
    togglePriceType (plan) {
      const newType = plan.price_type === 'percentage' ? 'euro' : 'percentage'
      this.$set(plan, 'price_type', newType)
    },
    addOption (plan) {
      const options = plan.options || []

      const newOption = {
        name: `Optie #${options.length + 1}`,
        price: '',
        selected: false
      }

      this.$set(plan, 'options', [...options, newOption])
    },
    removeOption (plan, i) {
      plan.options.splice(i, 1)
    }
  }
}
</script>
