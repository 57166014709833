<template>
  <div class="flex flex-wrap">
    <div v-for="(item, i) in content.items" :key="i" class="w-1/2 sm:w-full mb-4 px-3 flex items-center">
      <div class="mr-5" :style="{ color: settings.icon_color }">
        <div v-if="settings.numeric" class="w-3">{{ i + 1 }}.</div>
        <i v-else :class="`fa fa-${item.icon || 'check'} text-xl`"></i>
      </div>
      <p class="text-sm">{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
import templateMixin from './template'

export default {
  mixins: [templateMixin]
}
</script>
