const definitions = [
  {
    type: 'list',
    title: 'Checklist',
    icon: 'Double-check',
    content: {
      items: [
        { icon: 'check', text: 'Wij zetten onze kwaliteit graag voor je in' },
        { icon: 'check', text: 'Wij helpen je om de maximale opbrengst voor jouw huis te realiseren' },
        { icon: 'check', text: 'Wij houden je actief op de hoogte en bewaken alle stappen' },
        { icon: 'check', text: 'Via ons online systeem kun je jouw gegevens bekijken wanneer je dat wilt' }
      ]
    },
    settings_component: require('../settings/ListSettings').default,
    template: require('../../templates/IconList').default
  },
  {
    type: 'textbox',
    title: 'Tekstblok',
    icon: 'Text',
    content: {
      text: 'Vertel je verhaal...'
    },
    settings_component: require('../settings/TextboxSettings').default,
    template: require('../../templates/Text').default
  },
  {
    type: 'image',
    title: 'Afbeelding',
    icon: 'Picture',
    content: {
      image_url: 'https://app.nexxtmove.nl/static/images/placeholder.png'
    },
    settings_component: require('../settings/ImageSettings').default,
    template: require('../../templates/ImageFullWidth').default
  },
  {
    type: 'image_text',
    title: 'Afbeelding met tekst',
    icon: 'Layout-top-panel-5',
    content: {
      image_url: 'https://app.nexxtmove.nl/static/images/placeholder.png',
      text: 'Vertel je verhaal...'
    },
    settings_component: require('../settings/ImageTextSettings').default,
    template: require('../../templates/ImageWithText').default
  },
  {
    type: 'usps',
    title: 'USP\'s',
    icon: 'Star',
    content: {
      items: [
        { text: 'Professionele foto’s en video om jouw huis verleidelijk te presenteren', icon: 'camera' },
        { text: 'Een professionele presentatiebrochure van jouw huis', icon: 'brochure' },
        { text: 'Directe benadering van vip zoekers uit ons bestand', icon: 'vip' },
        { text: 'Raampresentatie in de etalage van ons kantoor', icon: 'window' },
        { text: 'Prominente positie op Funda', icon: 'marker' },
        { text: 'Opvallende te koop en verkocht borden', icon: 'sold' },
        { text: 'Gerichte exposure op Facebook en Instagram', icon: 'exposure' },
        { text: 'Een professionele en persoonlijke aanpak', icon: 'choices' }
      ]
    },
    settings_component: require('../settings/ListSettings').default,
    template: require('../../templates/Usps').default
  },
  {
    type: 'price_boxes',
    title: 'Pakketten',
    icon: 'Euro',
    content: {
      plans: [
        { name: 'Flexibel tarief', description: '1,8% inclusief BTW over de verkoopprijs van uw huis.', price: 595 },
        { name: 'Vast tarief', description: '', price: 3000 }
      ]
    },
    settings_component: require('../settings/PlanSettings').default,
    template: require('../../templates/Plans').default
  },
  {
    type: 'buttons',
    title: 'Knoppen',
    icon: 'Cursor',
    content: {
      buttons: [
        { text: 'Klik hier', action: 'link' }
      ]
    },
    settings_component: require('../settings/ButtonSettings').default,
    template: require('../../templates/Buttons').default
  },
  {
    type: 'video',
    title: 'YouTube video',
    icon: 'Youtube',
    content: {
      youtube_url: '',
      autoplay: false
    },
    settings_component: require('../settings/VideoSettings').default,
    template: require('../../templates/Video').default
  }
]

export default definitions

export const keyed = definitions.reduce((r, d) => ({ ...r, [d.type]: d }), {})
