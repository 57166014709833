<template>
  <div class="video-container" :class="{ 'pointer-events-none': state.editMode }">
    <iframe :src="embedUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
import templateMixin from './template'
import state from '../state'

export default {
  mixins: [templateMixin],
  data () {
    return {
      state
    }
  },
  computed: {
    embedUrl () {
      const url = this.content.youtube_url

      const splitter = url.includes('?v=') ? '?v=' : '/'
      const id = url.split(splitter).pop()

      let result = `https://www.youtube.com/embed/${id}`

      if (this.content.autoplay) {
        result += '?autoplay=1'
      }

      return result
    }
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
