import state from '../state'
import number from './number'
import { calculateTotalPrice } from '../templates/Plan.vue'
import { getPlans } from '../templates/Plans.vue'

const placeholders = {
  kosten_pakket (nr) {
    const availablePlans = getPlans(state.currentPage)

    const i = nr - 1
    const plan = availablePlans[i]
    if (!plan) {
      return
    }

    const price = calculateTotalPrice(plan)
    return number(price)
  }
}

export default {
  replace (text) {
    return text.replace(/{{(\w+) ?(\w+)?}}/g, (val, key, param) => {
      if (!placeholders[key]) {
        return val
      }

      return placeholders[key](param) || val
    })
  }
}
