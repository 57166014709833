<template>
  <div v-if="state.editMode">
    <DragContainer :items.sync="state.quote.pages" lock-axis="y" :tag="{ value: 'nav', props: { class: 'flex flex-col' } }">
      <Draggable v-for="(page, i) in state.quote.pages" :key="i">
        <MenuItem editable :label="page.title" :number="i + 1" @rename="rename(page.key, $event)" @remove="remove(page.key)" @click="openPage(i)" />
      </Draggable>
    </DragContainer>

    <MenuItem label="Pagina toevoegen" number="+" @click="state.addPage" />
  </div>

  <nav v-else class="flex flex-col">
    <MenuItem v-for="(page, i) in state.quote.pages" :key="i" :label="page.title" :number="i + 1" @click="openPage(i)" />
  </nav>
</template>

<script>
import MenuItem from './MenuItem.vue'
import DragContainer from '../editor/ui/DragContainer'
import { Draggable } from 'vue-smooth-dnd'
import state from '../state'
import swal from 'sweetalert'

export default {
  components: { MenuItem, DragContainer, Draggable },
  data () {
    return { state }
  },
  methods: {
    rename (key, title) {
      const page = state.quote.pages.find(p => p.key === key)
      page.title = title
    },
    remove (key) {
      swal({
        title: 'Weet je het zeker?',
        text: 'Eenmaal verwijderd is deze pagina niet meer te herstellen.',
        icon: 'warning',
        buttons: ['Annuleren', 'Verwijderen'],
        dangerMode: true
      })
        .then((willDelete) => {
          if (!willDelete) {
            return
          }

          state.quote.pages = state.quote.pages.filter(p => p.key !== key)
        })
    },
    openPage (i) {
      this.$router.push({ params: { page: i + 1 } })
    }
  }
}
</script>
