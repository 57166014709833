<template>
  <div v-html="text" class="textbox"></div>
</template>

<script>
import templateMixin from './template'
import placeholder from '../util/placeholder'

export default {
  mixins: [templateMixin],
  computed: {
    text () {
      return placeholder.replace(this.content.text)
    }
  }
}
</script>

<style>
.textbox h1 {
  @apply text-3xl;
}
.textbox h2 {
  @apply text-2xl;
}
.textbox h3 {
  @apply text-xl;
}
.textbox h4 {
  @apply text-sm;
}
.textbox h5 {
  @apply text-xs;
}
.textbox h6 {
  @apply text-2xs;
}

.textbox a {
  @apply text-secondary;
}

.textbox img {
  display: inline-block;
}
</style>
