<template>
  <Container v-bind="$attrs" @drop="onDrop">
    <slot />
  </Container>
</template>

<script>
import { Container } from 'vue-smooth-dnd'

export default {
  components: { Container },
  props: {
    items: Array
  },
  methods: {
    onDrop (dropResult) {
      const [newItems, itemAdded] = this.applyDrag(this.items, dropResult)
      this.$emit('update:items', newItems)
      this.$emit('drop', itemAdded)
    },
    applyDrag (arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return [result, itemToAdd]
    }
  }
}
</script>
