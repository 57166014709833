<template>
  <div>
    <h3 class="mb-3">{{ definition.title }} bewerken</h3>

    <div v-if="definition.settings_component" class="mb-5">
      <component :is="definition.settings_component" :row="row" />
    </div>

    <hr class="my-5">

    <Btn @click="showAdvanced = !showAdvanced" :class="{ 'bg-gray-200': showAdvanced }">
      <span class="mr-1">Geavanceerde instellingen</span>
      <i v-if="showAdvanced" class="fa fa-angle-up"></i>
      <i v-else class="fa fa-angle-down"></i>
    </Btn>

    <transition name="slide-down">
      <div v-if="showAdvanced" class="py-5">
        <div v-if="showColors">
          <p class="text-sm mb-2">Kleuren</p>
          <div class="mb-2">
            <label for="email" class="block text-xs text-gray-500">Tekstkleur</label>
            <div class="mt-1">
              <ColorPicker v-model="row.template_settings.text_color" />
            </div>
          </div>

          <div class="mb-2">
            <label for="email" class="block text-xs text-gray-500">Achtergrondkleur</label>
            <div class="mt-1">
              <ColorPicker v-model="row.template_settings.bg_color" />
            </div>
          </div>

          <div class="mb-2">
            <label for="email" class="block text-xs text-gray-500">Randkleur</label>
            <div class="mt-1">
              <ColorPicker v-model="row.template_settings.border_color" />
            </div>
          </div>

          <hr class="my-5">
        </div>

        <p class="text-sm mb-2">Marges</p>
        <div class="flex space-x-3">
          <div class="flex-1" v-for="type in spacingTypes" :key="type.key">
            <fieldset>
              <legend class="block text-xs text-gray-500">{{ type.name }}</legend>
              <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                <input type="number" v-model.number="row.template_settings[`${type.key}_top`]" class="relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 text-xs border-gray-300 text-center pr-0" title="Boven" placeholder="0">

                <div class="flex -space-x-px">
                  <div class="flex-1">
                    <input type="number" v-model.number="row.template_settings[`${type.key}_left`]" class="relative block w-full bg-transparent focus:z-10 text-xs border-gray-300 text-center pr-0" title="Links" placeholder="0">
                  </div>
                  <div class="flex-1">
                    <input type="number" v-model.number="row.template_settings[`${type.key}_right`]" class="relative block w-full bg-transparent focus:z-10 text-xs border-gray-300 text-center pr-0" title="Rechts" placeholder="0">
                  </div>
                </div>

                <input type="number" v-model.number="row.template_settings[`${type.key}_bottom`]" class="relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 text-xs border-gray-300 text-center pr-0" title="Onder" placeholder="0">
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Btn from '../../editor/ui/Btn.vue'
import ColorPicker from '../../editor/ui/ColorPicker.vue'
import state from '../../state'
import { keyed as definitions } from '../definitions/row-definitions'

export default {
  components: { Btn, ColorPicker },
  props: {
    row: Object
  },
  data () {
    return {
      showAdvanced: false,
      state
    }
  },
  computed: {
    definition () {
      return definitions[this.row.content_type]
    },
    spacingTypes () {
      return [
        { name: 'Binnen', key: 'padding' },
        { name: 'Buiten', key: 'margin' }
      ]
    },
    showColors () {
      const disable = ['image']
      return !disable.includes(this.row.content_type)
    }
  }
}
</script>
