<template>
  <div>
    <label class="block text-2xs">Youtube video URL</label>
    <div class="mt-1 mb-3 flex rounded-md shadow-sm">
      <input type="text" placeholder="Bijv. https://www.youtube.com/watch?v=B7UmUX68KtE" v-model="row.content.youtube_url" class="flex-1 rounded-md block px-3 py-1 rounded-none border-gray-300 text-2xs cursor-text">
    </div>

    <div class="flex items-center mb-3">
      <input id="autoplay" v-model="row.content.autoplay" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
      <label for="autoplay" class="text-2xs ml-1">Automatisch afspelen</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: Object
  }
}
</script>
