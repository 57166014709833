<template>
  <div class="border-b-2 border-gray-200">
    <nav class="-mb-px flex space-x-5" aria-label="Tabs">
      <a v-for="tab in tabs" :key="tab.name" href="#" :class="[tab.current ? 'border-nexxtmove text-nexxtmove' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined" @click="$emit('select', tab)">
        {{ tab.name }}
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array
  }
}
</script>
