<template>
  <div>
    <label class="block text-xs">Titel</label>
    <div class="mt-1 mb-5">
      <input type="text" v-model="state.quote.title" class="w-full p-3 rounded-md shadow-sm border-gray-300 text-xs cursor-text">
    </div>

    <label class="block text-xs">Link</label>
    <div class="mt-1 flex shadow-sm ">
      <button class="inline-flex items-center px-3 pr-0 rounded-l-md border border-r-0 border-gray-300 bg-white hover:bg-gray-50 text-xs text-gray-500 cursor-pointer">
        offerte.nexxtmove.nl/
      </button>
      <input type="text" v-model="state.quote.slug" class="list-item__text flex-1 min-w-0 block w-full p-3 pl-0 rounded-r-md border-l-0 border-gray-300 text-xs cursor-text">
    </div>
    <div class="rounded-b-md bg-yellow-50 p-4 mb-5">
      <p class="text-xs text-yellow-700">
        Offerte beschermen tegen ongevraagde bezoekers? Zorg dat deze link niet te raden is. Bijvoorbeeld door het toevoegen van een code.
      </p>
    </div>

    <div class="flex items-center mb-3">
      <input id="showTitle" v-model="state.quote.settings.show_title" type="checkbox" class="focus:ring-primary h-3 w-4 text-primary border-gray-300 rounded" />
      <label for="showTitle" class="text-xs ml-1">Titel weergeven</label>
    </div>

    <label class="block text-xs">Medewerker</label>
    <select v-model="state.quote.employee_id" class="mt-3 w-full p-3 rounded-md shadow-sm border-gray-300 text-xs">
      <option value="" disabled selected>Koppel een medewerker</option>
      <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
    </select>

    <div class="rounded-b-md bg-blue-50 p-4 mb-5">
      <p class="text-xs text-blue-700">
        De mails vanuit deze offerte worden naar de gekoppelde medewerker gestuurd.
      </p>
    </div>

    <label class="block text-xs mb-1">Achtergrondafbeelding</label>
    <ImageSettings @change="updateBackgroundImage" class="inline-flex" />
    <button v-if="hasBackgroundImage" title="Afbeelding verwijderen" class="inline-flex items-center px-2 h-6 text-2xs text-gray-400 hover:bg-gray-50" @click="resetBackgroundImage">
      <i class="fa fa-reply"></i>
    </button>
  </div>
</template>

<script>
import state from '../../state'
import api from '../../util/api'
import ImageSettings from './ImageSettings'

export default {
  components: { ImageSettings },
  data () {
    return {
      state,
      employees: null
    }
  },
  computed: {
    hasBackgroundImage () {
      return !!state.quote.background_image_url
    }
  },
  created () {
    this.loadEmployees()
  },
  methods: {
    loadEmployees () {
      api.get('/employees')
        .then((result) => {
          this.employees = result.data.data
        })
    },
    updateBackgroundImage (url) {
      state.quote.background_image_url = url
    },
    resetBackgroundImage () {
      state.quote.background_image_url = null
    }
  }
}
</script>
