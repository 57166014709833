<template>
  <Container group-name="content" behaviour="copy" :tag="{ value: 'div', props: { class: 'mt-5 grid grid-cols-2 gap-5' } }" :get-child-payload="getRow">
    <Draggable v-for="(definition, i) in definitions" :key="definition.type">
      <ContentTile :definition="definition" :icon-class="colors[i % 5]" />
    </Draggable>
  </Container>
</template>

<script>
import ContentTile from './DefinitionTile.vue'
import state from '../../state'
import definitions from './row-definitions'
import { nanoid } from 'nanoid'
import { Container, Draggable } from 'vue-smooth-dnd'

export default {
  components: { ContentTile, Container, Draggable },
  data () {
    return {
      state
    }
  },
  computed: {
    definitions () {
      return definitions
    },
    colors () {
      return [
        'text-blue-500',
        'text-green-500',
        'text-pink-500',
        'text-yellow-500',
        'text-purple-500'
      ]
    }
  },
  methods: {
    createRow (definition) {
      return {
        key: nanoid(),
        content_type: definition.type,
        content: this.deepClone(definition.content || {}),
        template_settings: {
          padding_top: 1,
          padding_bottom: 1,
          ...(this.deepClone(definition.template_settings || {}))
        }
      }
    },
    deepClone (val) {
      return JSON.parse(JSON.stringify(val))
    },
    addRow (definition) {
      const row = this.createRow(definition)
      this.$emit('add', row)
    },
    getRow (index) {
      const definition = definitions[index]
      return this.createRow(definition)
    }
  }
}
</script>
